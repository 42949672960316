import { Poppins, Quicksand } from "next/font/google";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
const quicksand = Quicksand({ weight: "400", subsets: ["latin"] });
const popins =Poppins({weight:"400",subsets:['latin']})
const extraPopins =Poppins({weight:"500",subsets:['latin']})
export default function TrendingCourses({CourseListdata,hideTitle}) {
  return (
    <>
    <section className="container">
    {hideTitle ? null :( <div className="df fjc fac ">
            <div className="top-cate df fac fjsa">
              <Image
                className="img pdt3"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
              <h4 className={`fs-33 mfs-20 ${popins.className}`}>Our Trending Courses</h4>
              <Image
                className="img pdt3"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
            </div>
          </div>) }
          <div  className={`df fjc fw mdf mfjc mfac gap20 mpdl0 pdt30 pdb30 mpdt10 `}>
            {CourseListdata.map((items,index) => {
              return (

        <div className="card-box mt22" key={index}>
          <div className=" df fjc pdt16">
            <div className="">
              <Link href={`/training/${items.path}-training`}>
                <Image
                  className="img-radius mdn"
                  src={`/course-img/bg-img/${items.path}.webp`}
                  quality={100}
                  width={355}
                  height={325}
                  alt={`${items.courseName}-Iamge`}
                  loading="lazy"
                />
              </Link>
              <Link href={`/training/${items.path}-training`}>
                <Image
                  className="img-radius dn mdb"
                  src={`/course-img/bg-img/${items.path}.webp`}
                  quality={100}
                  width={254}
                  height={170}
                  alt={`${items.courseName}-Iamge`}
                  loading="lazy"
                />
              </Link>
            </div>

           
          </div>
          <div className="df fjsb mt20">
            <p className={`fs-14 pdl20 ${extraPopins.className}`}>Days:{items.courseHours}</p>
            <p className={`fs-14 pdr20 ${extraPopins.className}`}>{items.rating} <span><FaStar size={10}/></span> ({`${items.courseEnrolled}`})</p>
          </div>
          <Link href={`/training/${items.path}-training`}>
          <div className="h20 cursor mt16">      
                <p className={`fs-16 tac ${extraPopins.className}`}>{items.courseName}</p>
          </div>
          </Link>
          <div className="tac pdlr10 mt28">
            <Link href={`/training/${items.path}-training`}>  <button className={` btn-box cursor fs-16 cw ${extraPopins.className}`}>Know More</button> </Link>
            </div>
        </div>

    )
})}
      </div>
      </section>
      <style jsx>
        {`
        .top-cate {
    background-color: #ffffff;
    width: 450px;
    height: 70px;
    border: 1px solid #fdbb39;
    border-radius: 46px;
    box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
  }
                  .card-box {
            width: 390px;
            height: 514px;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0px 4px 10px 0px #0000004D;
            transition: all 0.4s ease-in-out;
            padding: 5px 5px;

          }
            .btn-box {
              
              padding: 10px;
              border-radius: 10px;
              background-color:#FFA900;
              border: none;
              transition: all 0.4s ease-in-out;
              width: 100%;
               
            }

                @media only screen and (max-width: 768px){
                        .top-cate {
    background-color: #ffffff;
    width: 290px;
    height: 60px;
    border: 1px solid #fdbb39;
    border-radius: 46px;
    box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
  }
   }

           @media only screen and (max-width: 1023px){
             .card-box{
              width: 290px;
              height: auto;
              border-radius: 18px;
              background-color: #FFFFFF;
              box-shadow: 0px 8px 16px 0px #00000040;
              padding-bottom:10px;
 
          }

           }   
                    @media only screen and (max-width: 340px){
          
       .card-box{
              width: 100%; !important;
              height: auto;
              border-radius: 18px;
              background-color: #FFFFFF;
              box-shadow: 0px 8px 16px 0px #00000040;
              padding-bottom:10px;
 
        }
              
        }  
        `}
      </style>
    </>
  );
}
